import cn from "classnames";
import type { PropsWithChildren } from "react";

import styles from "./Title.module.css";

interface Props {
  className?: string;
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  style?: React.CSSProperties;
  id?: string;
  title?: string;
}

function Title({ className, level = 1, children, style, id, title }: PropsWithChildren<Props>) {
  const CustomTag = `h${level}` as const;

  return (
    <CustomTag title={title} id={id} style={style} className={cn(styles["title"], className)}>
      {children}
    </CustomTag>
  );
}

export default Title;
