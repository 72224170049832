/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Measures } from "@prisma/client";
import { Prisma } from "@prisma/client";
import { uniq } from "lodash";

import { addNormalized } from "~/lib/formats";
import impactIndicatorDependencies from "~/lib/impactDependencies.json";
import genuJson from "~/locales/genu.json";

import type {
  EcosystemicFunction,
  EcosystemicFunctionType,
  Genu,
  GenuIndicator,
  IndexType,
  IndicatorType,
} from "./types";

// @todo: Refactor & simplify genu lib

const genu = genuJson as Genu;

export const sortedImpactIndices: IndexType[] = [
  "biodiversityImpact",
  "climateImpact",
  "waterImpact",
  "yieldImpact",
];

export const isIndexType = (ind?: string | null): ind is IndexType => {
  return !!ind && (ind === "impactRating" || (sortedImpactIndices as unknown[]).includes(ind));
};

export const isGenuCode = (ind: string): ind is IndicatorType => {
  return (genu.indicators as Record<string, GenuIndicator>)[ind] !== undefined;
};

export const isIndicator = (ind: string): ind is IndicatorType => {
  return isGenuCode(ind) && genu.indicators[ind].type === "Indicator";
};

export const isMeasure = (ind: string): ind is IndicatorType => {
  return isGenuCode(ind) && genu.indicators[ind].type === "Measure";
};

export const isContextKey = (key: string): key is IndicatorType & keyof Measures => {
  return (
    isGenuCode(key) &&
    genu.indicators[key].index === "Context" &&
    !!Prisma.validator<Prisma.MeasuresUpdateInput>()({ [key]: null } as {
      [key: string]: never;
    })
  );
};

export const isEcosystemicFunction = (key: string): key is EcosystemicFunctionType => {
  return (genu.ecosystemicFunctions as Record<string, EcosystemicFunction>)[key] !== undefined;
};

// get the nested indicator dependencies
export const getImpactIndicatorDependencies = (
  key: IndexType | IndicatorType
): Array<IndexType | IndicatorType> => {
  return uniq(
    (
      ((impactIndicatorDependencies.dependencies as any)?.[key]?.dependencies ?? []) as Array<
        IndexType | IndicatorType
      >
    ).flatMap((dependency) => {
      const deps = getImpactIndicatorDependencies(dependency);
      if (deps.length === 0) {
        return [dependency];
      } else {
        return [dependency, ...deps];
      }
    })
  );
};

export const getImpactIndicatorNormalizedDependencies = (key: IndexType | IndicatorType) => {
  return getImpactIndicatorDependencies(key).filter((key) => key.endsWith("_normalized"));
};

export const indToGetter = (ind: GenuIndicator) => {
  if (!ind.code) {
    return "rating.impactRating";
  }

  return isIndexType(ind.code) ? `rating.${ind.code}` : `indicators.${addNormalized(ind.code)}`;
};

export default genu;
