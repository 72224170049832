import cn from "classnames";
import type { InputHTMLAttributes, ReactNode, Ref } from "react";
import { forwardRef } from "react";

import Title from "~/components/atoms/Title";
import WarningIcon from "~/components/icons/WarningIcon";

import styles from "./TextInput.module.css";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  name?: string;
  type?: InputHTMLAttributes<HTMLInputElement>["type"];
  className?: string;
  label?: string;
  description?: ReactNode;
  error?: string | null;
  helper?: string | null;
  touched?: boolean;
  icon?: ReactNode;
  iconRight?: ReactNode;
  block?: boolean;
}

const TextInput = forwardRef(
  (
    {
      className,
      label,
      description,
      error,
      helper,
      id,
      name,
      type,
      icon,
      iconRight,
      block,
      touched,
      disabled,
      ...rest
    }: Props,
    ref: Ref<HTMLInputElement>
  ) => {
    const showError = error && touched === true;

    return (
      <label className={cn(styles.root, className)}>
        {label && <Title level={6}>{label}</Title>}

        {description && <p className="text-sm text-gray-700">{description}</p>}

        <div
          className={cn("relative rounded", {
            "mt-2": label,
          })}
        >
          {icon && <div className={styles["ti-icon-left"]}>{icon}</div>}

          <input
            {...rest}
            ref={ref}
            disabled={disabled}
            className={cn(styles["ti-input"], "focus-ring", {
              "pl-10": icon,
              "pr-10": iconRight,
              "w-full": block,
              [styles["ti-error"]]: showError,
              [styles["ti-disabled"]]: disabled,
            })}
            id={id}
            name={name}
            type={type}
          />

          {iconRight && !showError && <div className={styles["ti-icon-right"]}>{iconRight}</div>}

          {showError && (
            <div className={styles["ti-icon-right"]}>
              <WarningIcon className="w-6 h-6 text-red-500" />
            </div>
          )}
        </div>

        {helper && <p className="mt-1 text-sm text-gray-500">{helper}</p>}
        {showError && <p className="mt-1 text-sm text-red-500 ">{error}</p>}
      </label>
    );
  }
);

TextInput.displayName = "TextInput";

export default TextInput;
