/* eslint-disable max-lines */
import type { Exploitation, ExploitationOrganizationAuthorization, Parcel } from "@prisma/client";
import dayjs from "dayjs";
import { get, isBoolean } from "lodash";

import type { ExploitationRatedParcel } from "~/hooks/useExploitationsRatedParcels";
import { addNormalized } from "~/lib/formats";
import { isIndexType, isIndicator } from "~/lib/genu";
import {
  getParcelLatestCalculatedPractice,
  getParcelLatestDerivedMeasure,
  getParcelLatestInformation,
  getParcelLatestMeasures,
} from "~/lib/parcelGetter";

import { isLegacyParcelRating, type ParcelDerivedMeasure } from "../rated";
import type { PartialDeep } from "../types";

export type FilterValue = string | boolean | [number, number] | number | null;
export interface AndFilters<T = FilterValue> {
  AND: T[][];
}
type ParcelType = Partial<Parcel> &
  PartialDeep<
    ExploitationRatedParcel<
      Exploitation & { authorizations: ExploitationOrganizationAuthorization[] }
    >
  >;

// if the filter is 2 number ([min, max]), the filter is a range
// if the filter is an array of strings, this is considered as an OR (except for culturesInRotation)
// keys are of the form "information.[informationKey]" or "measuresCompleted.[measuresKey]".

// culturesInRotationExclusion: This excludes all parcels with any of the cultures in this filter.
// campaign: filters ratings and indicators given for this campaign.
// exploitation: filter exploitationIds

export interface Filters {
  [key: string]: FilterValue[] | AndFilters;
}

export const isRangeFilter = (filter: Array<FilterValue>): filter is [number, number][] => {
  return Array.isArray(filter) && filter.every(isRangeFilterValue);
};

export const isRangeFilterValue = (value: FilterValue): value is [number, number] => {
  return (
    Array.isArray(value) &&
    value.length === 2 &&
    (value as unknown[]).every((value) => typeof value === "number")
  );
};

const parcelFilterPredicate = <T extends ParcelType>(
  parcel: T,
  key: string,
  values: FilterValue[]
) => {
  if (values.length === 0) {
    return true;
  }

  if (key === "culturesInRotation") {
    return (
      parcel.cultures?.length &&
      values.some((value) => parcel.cultures?.some(({ codeCulture }) => codeCulture === value))
    );
  }

  if (key === "culturesInRotationExclusion") {
    // @todo special case for the culturesInRotationExclusion filter, since it's an "and" filter
    return (
      !parcel.cultures ||
      values.every((value) => parcel.cultures?.every(({ codeCulture }) => codeCulture !== value))
    );
  }

  if (key === "currentCulture") {
    // @todo special case for the currentCulture filter, which is not getter-based
    // cultures are assumed to be sorted by descending dates
    return parcel.cultures?.length && values.includes(parcel.cultures![0].codeCulture);
  }

  if (key === "campaign") {
    return (
      parcel.ratings &&
      values.some((value) => parcel.ratings?.some(({ campaign }) => campaign?.name === value))
    );
  }

  if (key === "exploitation") {
    return values.includes(parcel.exploitationId);
  }

  if (key === "organization") {
    return values.some((value) =>
      parcel.exploitation?.authorizations?.some(({ organizationId }) => organizationId === value)
    );
  }

  if (key === "country") {
    return values.includes(parcel.exploitation?.country?.toLowerCase());
  }

  if (key === "exploitationGroups") {
    return values.some((value) =>
      parcel.exploitation?.exploitationGroups?.some(
        (exploitationGroup) => exploitationGroup.toLowerCase() === value
      )
    );
  }

  if (key.startsWith("rating.")) {
    if (!parcel.ratings?.length) {
      return false;
    }
    // we make a particular case from the measures, since a parcel might have several measures (in its HSU intersections)
    // the filter hits if at least one measure matches the filter
    const [rating] = parcel.ratings;
    const [, ratingKey] = key.split(".");
    if (!isIndexType(ratingKey)) {
      return true;
      // New parcel rating cannot match on anything other than impactRating
    } else if (!isLegacyParcelRating(rating)) {
      if (ratingKey !== "impactRating") {
        return false;
      }

      const value = rating[ratingKey];
      if (value === null || value === undefined) {
        return false;
      }

      return matchValue(Math.round(value), values);
    } else {
      const value = rating[ratingKey];
      if (value === null || value === undefined) {
        return false;
      }

      return matchValue(Math.round(value), values);
    }
  }

  if (key.startsWith("indicators.")) {
    if (!parcel.indicators?.length) {
      return false;
    }
    // we make a particular case from the measures, since a parcel might have several measures (in its HSU intersections)
    // the filter hits if at least one measure matches the filter
    const [indicators] = parcel.indicators; // @todo handle campaigns here
    const [, indicatorKey] = key.split(".");
    if (!isIndicator(indicatorKey)) {
      return true;
    } else {
      const value = (indicators as Record<string, number>)[addNormalized(indicatorKey)];
      if (value === null) {
        return false;
      }
      return matchValue(Math.round(value), values);
    }
  }

  if (key.startsWith("measuresCompleted.")) {
    // we make a particular case from the measures, since a parcel might have several measures (in its HSU intersections)
    // the filter hits if at least one measure matches the filter
    const measures = getParcelLatestMeasures(parcel, key);
    return measures?.some((measure) => matchValue(measure, values));
  }

  if (key.startsWith("information.")) {
    // we make a particular case from the information because with the new parcels season model we have keys that are incompatible getters.
    // `information.metric` should actually fetch `informations[0].metric`.

    if (key === "information.coverCrop") {
      return matchCoverCrop(parcel, values);
    } else if (key === "information.plantationAge") {
      const plantationDate = getParcelLatestInformation(parcel, "information.plantationDate");
      const plantationAge = plantationDate ? dayjs().diff(plantationDate, "year") : undefined;
      return matchValue(plantationAge, values);
    } else {
      const information = getParcelLatestInformation(parcel, key);
      return matchValue(information, values);
    }
  }

  if (key.startsWith("derivedMeasures.")) {
    const [, measureKey] = key.split(".");
    const practice = getParcelLatestDerivedMeasure(
      parcel,
      measureKey as keyof ParcelDerivedMeasure
    );
    return matchValue(practice, values);
  }

  if (key.startsWith("calculatedPractices.")) {
    const practice = getParcelLatestCalculatedPractice(
      parcel,
      key,
      parcel.ratings?.[0]?.campaign?.name
    );
    return matchValue(practice, values);
  }

  // default is a standard getter.
  const value = get(parcel, key);
  return matchValue(value, values);
};

export function parcelFilter<T extends ParcelType>(parcel: T, filters: Filters): boolean {
  return Object.entries(filters).every(([key, values]) => {
    if (isAndFilter(values)) {
      return values["AND"].every((values) => parcelFilterPredicate(parcel, key, values));
    }

    return parcelFilterPredicate(parcel, key, values);
  });
}

// @todo retro compatibility on the measures fields,
// make sure we handle campaigns correctly
export function parcelsFilter<T extends ParcelType>(parcels: Array<T>, filters: Filters): Array<T> {
  const filteredParcels = parcels.filter((parcel) => parcelFilter(parcel, filters));

  // campaignFilter is a special case, since it filters ratings and indicators
  const campaignFilter = filters["campaign"] as FilterValue[];
  if (campaignFilter !== undefined && campaignFilter.length > 0) {
    return filteredParcels.map((parcel) => ({
      ...parcel,
      ratings: parcel.ratings?.filter(
        (rating) => rating.campaign?.name && campaignFilter.includes(rating.campaign.name)
      ),
      indicators: parcel.indicators?.filter(
        (indicator) => indicator.campaign?.name && campaignFilter.includes(indicator.campaign.name)
      ),
      // @todo filter hsu intersections as well.
    }));
  }

  return filteredParcels;
}

export function exploitationsFilter<
  T extends Exploitation & {
    parcels: ParcelType[];
  },
>(exploitations: Array<T>, filters: Filters): Array<T> {
  return exploitations?.map((exploitation) => {
    return {
      ...exploitation,
      parcels: parcelsFilter(
        exploitation.parcels.map((parcel) => ({ ...parcel, exploitation })),
        filters
      ),
    };
  });
}

export const matchValue = (
  value: string | boolean | number | string[] | undefined,
  values: FilterValue[]
) => {
  if (value === undefined) {
    return false;
  } else if (!values.length) {
    return true;
  } else if (isRangeFilter(values) && typeof value === "number") {
    return values.some(([min, max]) => value >= min && value <= max);
  } else if (Array.isArray(value) && values.some((filterValue) => value.includes(filterValue))) {
    return true;
  } else if (values.includes(value)) {
    return true;
  }

  return false;
};

export const matchCoverCrop = (parcel: ParcelType, values: Array<FilterValue>) => {
  if (values.length !== 1 || !isBoolean(values[0])) {
    return false;
  }

  const getters = [
    "information.shortCoverCrop",
    "information.longCoverCrop",
    "information.permanentCoverCrop",
  ];

  const predicate = (getter: string) => {
    const information = getParcelLatestInformation(parcel, getter);
    return information !== undefined ? matchValue(information, values) : false;
  };

  return values[0] ? getters.some(predicate) : getters.every(predicate);
};

export const filterSort = (lhs: FilterValue, rhs: FilterValue) => {
  if (lhs === "none" || lhs === "other" || lhs === null) {
    return 1;
  } else if (rhs === "none" || rhs === "other" || rhs === null) {
    return -1;
  } else if (
    // test if the string is a range
    Array.isArray(lhs) &&
    Array.isArray(rhs)
  ) {
    return lhs[0] > rhs[0] ? 1 : -1;
  } else {
    return lhs > rhs ? 1 : -1;
  }
};

export const isAndFilter = <T>(filter?: T[] | AndFilters<T>): filter is AndFilters<T> =>
  filter !== undefined && (filter as unknown as Record<string, unknown>)["AND"] !== undefined;
