const seo = {
  title: "Genesis",
  titleTemplate: "%s - Genesis",
  description: "Genesis Platform",
  openGraph: {
    type: "website",
    locale: "fr_FR",
    url: "https://www.genesis.green",
    site_name: "Genesis",
  },
};

export default seo;
