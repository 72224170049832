const ChevronDownIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg fill="none" {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3001 9.3C15.4927 9.15557 15.7309 9.08545 15.971 9.10252C16.2111 9.11958 16.437 9.22268 16.6072 9.39289C16.7774 9.5631 16.8805 9.789 16.8976 10.0291C16.9146 10.2692 16.8445 10.5074 16.7001 10.7L12.7001 14.7C12.5132 14.8832 12.2619 14.9859 12.0001 14.9859C11.7383 14.9859 11.487 14.8832 11.3001 14.7L7.3001 10.7C7.15567 10.5074 7.08555 10.2692 7.10261 10.0291C7.11968 9.789 7.22278 9.5631 7.39299 9.39289C7.5632 9.22268 7.7891 9.11958 8.02921 9.10252C8.26932 9.08545 8.50753 9.15557 8.7001 9.3L12.0001 12.59L15.3001 9.29V9.3Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChevronDownIcon;
