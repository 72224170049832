import Script from "next/script";
import type { Session } from "next-auth";
import { useEffect } from "react";

import useLocale from "~/hooks/useLocale";

export const useInitJimo = (session: Session | null) => {
  const locale = useLocale();

  useEffect(() => {
    if (!session?.impersonator?.email && session?.user.id) {
      if (!window.jimo) {
        window.jimo = [];
      }

      window.jimo.push(["do", "identify", [session?.user.id.toString()]]);
      window.jimo.push(["set", "core:language", [locale]]);

      window.jimoInit?.();
    }
  }, [session?.impersonator?.email, session?.user.id, locale]);
};

const JimoScript = () => {
  if (!process.env.NEXT_PUBLIC_JIMO_PROJECT_ID) {
    return null;
  }

  return (
    <Script id="jimo-script" strategy="afterInteractive">
      {`
        const addJimoScript = (window) => {
          if (!window.jimo) {
            window.jimo = [];
          }

          const script = document.createElement("script");

          script.type = "text/javascript";
          script.async = true;
          script.src = "https://undercity.usejimo.com/jimo-invader.js";
          window["JIMO_PROJECT_ID"] = "${process.env.NEXT_PUBLIC_JIMO_PROJECT_ID}";
          window["JIMO_MANUAL_INIT"] = true;

          const head = document.getElementsByTagName("head")[0];
          head?.appendChild(script);
        }

        addJimoScript(window);`}
    </Script>
  );
};

export default JimoScript;
