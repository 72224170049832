import type { Session } from "next-auth";

export const HJID = process.env.NEXT_PUBLIC_HOTJAR_ID;
export const HJSV = process.env.NEXT_PUBLIC_HOTJAR_SV;

export const identifiers = (session: Session | null, currentUserProfile?: string) => {
  return {
    userId: `${session?.impersonator?.email ? `${session?.impersonator?.email}+` : ""}${
      session?.user.email
    }`,
    traits: {
      email: session?.impersonator?.email ?? session?.user.email,
      impersonating: session?.user.email,
      impersonated: !!session?.impersonator?.email,
      ...(currentUserProfile ? { [currentUserProfile]: true } : {}),
    },
  };
};
