const ChevronUpIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg fill="none" {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.7001 14.7C8.50753 14.8444 8.26932 14.9146 8.02921 14.8975C7.7891 14.8804 7.5632 14.7773 7.39299 14.6071C7.22278 14.4369 7.11968 14.211 7.10261 13.9709C7.08555 13.7308 7.15567 13.4926 7.3001 13.3L11.3001 9.30002C11.487 9.11679 11.7383 9.01416 12.0001 9.01416C12.2619 9.01416 12.5132 9.11679 12.7001 9.30002L16.7001 13.3C16.8445 13.4926 16.9146 13.7308 16.8976 13.9709C16.8805 14.211 16.7774 14.4369 16.6072 14.6071C16.437 14.7773 16.2111 14.8804 15.971 14.8975C15.7309 14.9146 15.4927 14.8444 15.3001 14.7L12.0001 11.42L8.7001 14.72V14.7Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChevronUpIcon;
