/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { InitOptions } from "i18next";
import i18n from "i18next";
import i18nextLocizeBackend from "i18next-locize-backend";
import locizeLastUsed from "locize-lastused";
import { initReactI18next } from "react-i18next";

const plugins: any[] = [initReactI18next];

export default function initializeI18n(locale?: string) {
  const i18nInstance = i18n;

  const config: InitOptions = {
    defaultNS: "translation",
    compatibilityJSON: "v3",
    lng: locale ?? "en",
    fallbackLng: "en", // use en if detected lng is not available
    saveMissing: process.env.NODE_ENV === "development" && !process.env.MANUAL_TRANSLATION_SAVE, // send not translated keys to endpoint
    saveMissingTo: "fallback",
    debug: false,
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
    react: { useSuspense: false },
  };

  if (
    process.env.NEXT_PUBLIC_LOCIZE_API_KEY &&
    process.env.NEXT_PUBLIC_LOCIZE_PROJECT_ID &&
    process.env.NODE_ENV !== "production"
  ) {
    // use locize when it's set up
    config.backend = {
      projectId: process.env.NEXT_PUBLIC_LOCIZE_PROJECT_ID,
      apiKey: process.env.NEXT_PUBLIC_LOCIZE_API_KEY,
      referenceLng: "en", // optional event triggered on saved to backend
      allowedAddOrUpdateHosts: ["localhost"],
      // requestOptions: {
      //   mode: "no-cors"
      // }
    };

    config.locizeLastUsed = {
      projectId: process.env.NEXT_PUBLIC_LOCIZE_PROJECT_ID,
      apiKey: process.env.NEXT_PUBLIC_LOCIZE_API_KEY,
      referenceLng: "en", // optional event triggered on saved to backend
      debounceSubmit: 90000,
      allowedHosts: ["localhost"],
    };

    plugins.push(i18nextLocizeBackend);
    plugins.push(locizeLastUsed);

    plugins.forEach((plugin) => i18nInstance.use(plugin));
  } else {
    // use versionned locale files
    config.resources = {
      en: {
        translation: require("~/locales/en/translation.json"),
      },
      fr: {
        translation: require("~/locales/fr/translation.json"),
      },
      "pt-BR": {
        translation: require("~/locales/pt-BR/translation.json"),
      },
      es: {
        translation: require("~/locales/es/translation.json"),
      },
    };
  }

  i18nInstance.init(config);

  return i18nInstance;
}
