import { useRouter } from "next/router";

const useCurrentUserProfile = () => {
  const router = useRouter();
  if (router.pathname.startsWith("/org")) {
    return "org";
  }

  if (router.pathname.startsWith("/farmers")) {
    return "farmers";
  }

  if (router.pathname.startsWith("/admin")) {
    return "admin";
  }

  if (router.pathname.startsWith("/samplers")) {
    return "samplers";
  }

  if (router.pathname.startsWith("/lab")) {
    return "lab";
  }

  return "unknown";
};

export default useCurrentUserProfile;
