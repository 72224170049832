import type { Session } from "next-auth/index";
import { useTranslation } from "react-i18next";

import { Button } from "~/components/ui";
import { useAdminImpersonate } from "~/hooks";

const ImpersonateControl = ({ session }: { session: Session }) => {
  const { t } = useTranslation();

  const { exitImpersonation } = useAdminImpersonate();

  return (
    <div className="fixed top-0 z-50 w-full h-2 transition-all bg-indigo-500 border-indigo-200 hover:bg-indigo-50 flex-center no-print group hover:h-12 hover:border-b">
      <div className="px-3 py-1 mx-auto text-sm font-medium transition-all rounded-b opacity-0 group-hover:opacity-100 flex-center">
        {t("admin.impersonating", "Impersonating {{email}}", {
          email: session!.user.email,
        })}
        <Button onClick={exitImpersonation} type="primary" className="!text-white ml-4">
          {t("admin.exitImpersonationCTA", "Exit")}
        </Button>
      </div>
    </div>
  );
};

export default ImpersonateControl;
