import { Menu } from "@headlessui/react";
import cn from "classnames";
import countryFlagEmoji from "country-flag-emoji";
import Link from "next/link";
import { useRouter } from "next/router";

import ChevronDownIcon from "../icons/ChevronDownIcon";
import ChevronUpIcon from "../icons/ChevronUpIcon";

interface LocaleData {
  name: string;
  flag?: string;
}

const localeToCountryMap: Record<string, LocaleData> = {
  fr: {
    name: "Français",
    flag: countryFlagEmoji.get("FR")?.emoji,
  },
  en: {
    name: "English",
    flag: countryFlagEmoji.get("US")?.emoji,
  },
  "pt-BR": {
    name: "Português",
    flag: countryFlagEmoji.get("BR")?.emoji,
  },
  es: {
    name: "Español",
    flag: countryFlagEmoji.get("ES")?.emoji,
  },
};

type Position = "top-right" | "top-left" | "bottom-right" | "bottom-left";

interface Props {
  menuPosition?: Position;
}

const LocaleSelector = ({ menuPosition = "top-right" }: Props) => {
  const { locale, locales, defaultLocale = "en", asPath: currentPath } = useRouter();

  const options = (locales ?? []).filter((value) => value !== locale);
  const currentLocale = locale ?? defaultLocale;

  return (
    <div className="relative inline-block w-80">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button className="flex items-center max-w-xs p-1 text-sm rounded-full focus-ring">
              <p className="text-xl">{localeToCountryMap[currentLocale]?.flag ?? currentLocale}</p>
              {open ? (
                <ChevronUpIcon className="w-6 h-6" />
              ) : (
                <ChevronDownIcon className="w-6 h-6" />
              )}
            </Menu.Button>

            <Menu.Items
              className={cn(
                "absolute flex flex-col w-48 origin-bottom-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none",
                {
                  "bottom-0 left-0 origin-bottom-left ml-10": menuPosition === "top-right",
                  "bottom-0 right-0 origin-bottom-right mr-10": menuPosition === "top-left",
                  "top-0 left-0 origin-top-left ml-10": menuPosition === "bottom-right",
                  "top-0 right-0 origin-top-right mr-10": menuPosition === "bottom-left",
                }
              )}
            >
              {options.map((locale) => (
                <Menu.Item key={locale}>
                  {({ active }) => (
                    <div>
                      <Link href={currentPath} locale={locale} scroll={false}>
                        <a
                          className={cn(
                            "flex justify-between w-full px-4 py-2 text-sm leading-5 text-left",
                            {
                              "bg-gray-200 text-gray-900": active,
                              "text-gray-700": !active,
                            }
                          )}
                        >
                          {localeToCountryMap[locale].name}
                        </a>
                      </Link>
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </>
        )}
      </Menu>
    </div>
  );
};

export default LocaleSelector;
