import type { FilterView } from "@prisma/client";
import slugify from "slugify";

import { sqMToHa, unaccent } from "~/lib/formats";

export const wait = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

/**
 * Returns shallow copy of array with leading values not validating predicate removed.
 */
export const filterHead = <T>(array: T[], predicate: (value: T) => boolean) => {
  const index = array.findIndex(predicate);
  return index === -1 ? [] : array.slice(index);
};

/**
 * Returns shallow copy of array with trailing values not validating predicate removed.
 */
export const filterTail = <T>(array: T[], predicate: (value: T) => boolean) => {
  const index = array.findLastIndex(predicate);
  return index === -1 ? [] : array.slice(0, index + 1);
};

/**
 * @params [comparator]: Returns a negative number if lhs is lower than rhs, 0 if lhs equals rhs, a positive number otherwise.
 */
export const maxWith = <T>(array: T[], comparator: (lhs: T, rhs: T) => number): T | undefined => {
  let result = array[0];

  for (let i = 1; i < array.length; ++i) {
    if (comparator(result, array[i]) < 0) {
      result = array[i];
    }
  }
  return result;
};

export const getTotalArea = (
  data: Array<{ area?: number | null }>,
  { unit = "ha" }: { unit?: "ha" | "sqm" } = {}
) =>
  unit === "ha"
    ? data.reduce((acc, { area }) => acc + (area ?? 0) * sqMToHa, 0)
    : data.reduce((acc, { area }) => acc + (area ?? 0), 0);

export const exploitationArea = (exploitation: { parcels: Array<{ area?: number | null }> }) =>
  getTotalArea(exploitation.parcels);

export const simpleSearch = (text: string, query: string) => {
  return unaccent(text.toLowerCase()).includes(unaccent(query.toLowerCase()));
};

export const stringToSlug = (value: string) => {
  const options = {
    replacement: "-", // replace spaces with replacement character, defaults to `-`
    lower: true, // convert to lower case, defaults to `false`
    strict: true, // strip special characters except replacement, defaults to `false`
    trim: true, // trim leading and trailing replacement chars, defaults to `true`
  };

  return slugify(value, options);
};

export const segmentSlug = (segment?: FilterView) => {
  if (!segment) {
    return "";
  }

  return segment.name ? `${segment.id}-${stringToSlug(segment.name)}` : segment.id.toString();
};
