import type { Analytics, Traits } from "@june-so/analytics-next";
import { AnalyticsBrowser } from "@june-so/analytics-next";
import type { EventParams } from "@june-so/analytics-next/dist/types/core/arguments-resolver";
import Router from "next/router";
import create from "zustand";

interface AnalyticsState {
  analytics: Analytics | null;
}

interface AnalyticsActions {
  identifyUser: (id: string, params: Traits) => void;
  pageView: () => void;
  trackEvent: (...params: EventParams) => void;
  setAnalytics: (analytics: Analytics) => void;
  loadAnalytics: () => void;
}

type AnalyticsStateWithActions = AnalyticsState & AnalyticsActions;

const initialState: AnalyticsState = {
  analytics: null,
};

const useAnalytics = create<AnalyticsStateWithActions>((set, get) => ({
  ...initialState,

  loadAnalytics: async () => {
    const analyticsAreLoaded = !!get().analytics;
    if (!analyticsAreLoaded && process.env.NEXT_PUBLIC_JUNE_ANALYTICS_KEY) {
      const [response] = await AnalyticsBrowser.load({
        writeKey: process.env.NEXT_PUBLIC_JUNE_ANALYTICS_KEY!,
      });

      set((state) => ({ ...state, analytics: response }));
    }
  },

  setAnalytics: (analytics: Analytics | null) => {
    set((state) => ({ ...state, analytics }));
  },

  pageView: () => {
    get().analytics?.page();
    get().analytics?.track(`page-view:${Router.pathname}`);
  },

  identifyUser: (id: string, params: Traits) => {
    get().analytics?.identify(id, params);
  },

  trackEvent: (...params: EventParams) => get().analytics?.track(...params),
}));

export default useAnalytics;
