import { Cursor_120 } from "@carbon/icons-react";
import cn from "classnames";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import contactIllu from "public/images/illustrations/demo/contact.png";
import deviceIllu from "public/images/illustrations/demo/device.png";
import farmIlluEn from "public/images/illustrations/demo/en/farm.png";
import hsuIlluEn from "public/images/illustrations/demo/en/hsu.png";
import indicatorsIlluEn from "public/images/illustrations/demo/en/indicators.png";
import scaleIlluEn from "public/images/illustrations/demo/en/scale.png";
import farmIlluFr from "public/images/illustrations/demo/fr/farm.png";
import hsuIlluFr from "public/images/illustrations/demo/fr/hsu.png";
import indicatorsIlluFr from "public/images/illustrations/demo/fr/indicators.png";
import scaleIlluFr from "public/images/illustrations/demo/fr/scale.png";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "react-use";

import Title from "~/components/atoms/Title";
import { LocaleSelector } from "~/components/common";
import { AirtableEmailContactForm } from "~/components/demo";
import { Button, Modal } from "~/components/ui";
const DemoModal = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const [demoModalDone, setDemoModalDone] = useLocalStorage("demoModalDone", false);
  const [currentStep, setCurrentStep] = useState<number>(0);

  const steps = useMemo(
    () => [
      {
        image: deviceIllu,
        overline: t("demoModal.intro.overline", "Introduction"),
        title: t("demoModal.intro.title", "Welcome to the Genesis demo app"),
        children: (
          <>
            <p className="mb-6">
              {t(
                "demoModal.intro.content",
                "This demo will show you the features of our platform and introduce you to our rating from the farmer's point of view."
              )}
            </p>
            <LocaleSelector />
          </>
        ),
      },
      {
        image: router.locale === "fr" ? farmIlluFr : farmIlluEn,
        overline: t("demoModal.intro.overline", "Introduction"),
        title: t("demoModal.introDisclaimer.title", "This demo shows a farm rated by Genesis"),
        children: (
          <p>
            {t(
              "demoModal.introDisclaimer.content",
              "You play as the owner of a fictional farm. The data presented is not representative of the health of existing soils."
            )}
          </p>
        ),
      },

      {
        image: router.locale === "fr" ? hsuIlluFr : hsuIlluEn,
        overline: t("demoModal.concepts.overline", "A few concepts"),
        title: t("demoModal.soilUnits.title", "1. Soil Units"),
        children: (
          <p>
            {t(
              "demoModal.soilUnits.content",
              "A Soil Unit is a homogenous zone that can span multiple parcels, whose pedological, cultural, and topological characteristics are similar enough to be the object of a single sampling and analysis."
            )}
          </p>
        ),
      },

      {
        image: router.locale === "fr" ? scaleIlluFr : scaleIlluEn,
        overline: t("demoModal.concepts.overline", "A few concepts"),
        title: t("demoModal.ratingScale.title", "2. The rating scale"),
        children: (
          <p>
            {t(
              "demoModal.ratingScale.content",
              "Scores are given on a normalized scale from -100 to +100. When relevant, normalization takes into account our referential of soil and the pedological context of the Soil Unit."
            )}
          </p>
        ),
      },

      {
        image: router.locale === "fr" ? indicatorsIlluFr : indicatorsIlluEn,
        overline: t("demoModal.concepts.overline", "A few concepts"),
        title: t("demoModal.indicators.title", "3. Indicators"),
        children: (
          <p>
            {t(
              "demoModal.indicators.content",
              "A Genesis score is computed from 35 indicators of agronomical soil characteristics, grouped into 3 indices: Biodiversity, Carbon, and Pollution."
            )}
          </p>
        ),
      },

      {
        image: contactIllu,
        overline: t("demoModal.contact.overline", "Contact"),
        title: t("demoModal.contact.title", "One last thing..."),
        children: (
          <>
            <p className="pb-6">
              {t(
                "demoModal.contact.content",
                "Before starting the demo, you can leave us your email address if you'd like to be contacted about Genesis."
              )}
            </p>
            <AirtableEmailContactForm />
            <p className="pt-4 text-sm text-gray-700">
              {t(
                "demoModal.contact.disclaimer",
                "We'll never share your email with anyone else, and you can unsubscribe at any time."
              )}
            </p>
          </>
        ),
      },
    ],
    [t, router.locale]
  );

  const content = steps[currentStep];
  const isFirst = currentStep === 0;
  const isLast = currentStep === steps.length - 1;
  const next = () => {
    if (!isLast) setCurrentStep(currentStep + 1);
  };
  const prev = () => {
    if (!isFirst) setCurrentStep(currentStep - 1);
  };
  const reset = () => {
    setCurrentStep(0);
    setDemoModalDone(false);
  };

  if (demoModalDone) {
    return (
      <div className="fixed bottom-0 left-0 z-50 flex justify-center m-2 pointer-events-none md:right-0 md:m-6">
        <div className="flex items-center p-3 space-x-2 bg-white rounded shadow-lg pointer-events-auto">
          <div className="hidden w-8 h-8 text-indigo-600 bg-indigo-100 rounded md:flex-center md:block">
            <Cursor_120 />
          </div>
          <span className="hidden tracking-wide text-indigo-600 uppercase lg:pr-24 md:inline-block">
            {t("demoModal.demoMode", "Demo mode")}
          </span>
          <Button onClick={reset} type="text">
            {t("demoModal.reset", "Restart tutorial")}
          </Button>
          <Link passHref href="https://app.genesis.live">
            <Button type="primary" Component="a">
              {t("demoModal.createAccount", "Create an account")}
            </Button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <Modal width="large" unpadded open={true} setOpen={() => null} closeable={false}>
      <div className="flex flex-col-reverse md:flex-row">
        <div className="flex flex-col flex-1 p-6 md:p-12 md:min-h-[30rem] md:max-w-lg">
          <div className="flex pb-12 space-x-2">
            {steps.map((s, i) => (
              <button
                key={i}
                className={cn("block rounded-full w-2 h-2", {
                  "bg-gray-200": i !== currentStep,
                  "bg-indigo-500": i === currentStep,
                })}
                onClick={() => setCurrentStep(i)}
              />
            ))}
          </div>
          <p className="pb-2 text-indigo-500 text-overline">{content.overline}</p>
          <Title level={3} className="pb-6">
            {content.title}
          </Title>
          {content.children}
          <div className="flex justify-between w-full pt-6 mt-auto">
            {isFirst ? <div /> : <Button onClick={prev}>{t("demoModal.prev", "Previous")}</Button>}
            {isLast ? (
              <Button onClick={() => setDemoModalDone(true)} type="primary">
                {t("demoModal.close", "Close")}
              </Button>
            ) : (
              <Button onClick={next} type="primary">
                {t("demoModal.next", "Next")}
              </Button>
            )}
          </div>
        </div>
        <div className="relative w-full bg-gray-200 md:flex-1 h-72 md:w-auto md:h-auto">
          <Image
            draggable="false"
            layout="fill"
            placeholder="blur"
            objectFit="cover"
            src={content.image}
            alt=""
          />
        </div>
      </div>
    </Modal>
  );
};

export default DemoModal;
