const WarningIcon = ({ ...props }) => {
  return (
    <svg {...props} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 16C2 8.3 8.3 2 16 2C23.7 2 30 8.3 30 16C30 23.7 23.7 30 16 30C8.3 30 2 23.7 2 16ZM14.5 23.5C14.5 24.3 15.2 25 16 25C16.8 25 17.5 24.3 17.5 23.5C17.5 22.7 16.8 22 16 22C15.2 22 14.5 22.7 14.5 23.5ZM14.9 19V8H17.1V19H14.9Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default WarningIcon;
