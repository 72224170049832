import * as Sentry from "@sentry/nextjs";
import Image from "next/image";
import notFoundImage from "public/images/illustrations/not-found.png";
import type { ReactNode } from "react";
import React from "react";
import type { WithTranslation } from "react-i18next";
import { withTranslation } from "react-i18next";

import Title from "~/components/atoms/Title";
import NotFound from "~/pages/404";

class ErrorBoundary extends React.Component<
  WithTranslation & { children: ReactNode },
  { hasError: boolean; error?: Error }
> {
  constructor(props: WithTranslation & { children: ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error) {
    Sentry.captureException(error);
  }

  render() {
    const { t } = this.props;
    if (this.state.error?.message === "error.notFound") {
      // 404 page
      return <NotFound />;
    } else if (this.state.hasError) {
      return (
        <div className="flex flex-col items-center justify-center h-screen space-y-6">
          <Title level={2} className="pb-6">
            {this.state.error?.message && this.state.error.message.startsWith("error.")
              ? t(this.state.error.message, "Something went wrong.")
              : t("error.generic", "Something went wrong.")}
          </Title>
          <div className="relative w-64 h-64 max-w-full">
            <Image src={notFoundImage} alt="Not found" layout="fill" objectFit="contain" />
          </div>
          {/* Solving the error might require a full refresh */}
          {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
          <a href="/" className="link">
            {t("error.homelink", "Back to the homepage.")}
          </a>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

export default withTranslation()(ErrorBoundary);
