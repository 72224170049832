import create from "zustand";

import { immer } from "~/lib/zustand";

export interface INotification {
  text: string;
  type: "success" | "error" | "info";
  timeout?: number;
}

interface NotificationState {
  id: number;
  notifications: (INotification & { id: number })[];
  addNotification: (notification: INotification) => void;
  removeNotification: (id: number) => void;
}

const useNotificationState = create<NotificationState>(
  immer((mutate) => ({
    id: 0,
    notifications: [],

    addNotification: (notification: INotification) => {
      mutate((draft) => {
        draft.id++;
        draft.notifications.push({
          timeout: 2000, // default timeout
          ...notification,
          id: draft.id,
        });
      });
    },

    removeNotification: (id: number) => {
      mutate((draft) => {
        draft.notifications = draft.notifications.filter((notification) => notification.id !== id);
      });
    },
  }))
);

export default useNotificationState;
