import type { AnimationConfigWithPath, AnimationItem } from "lottie-web";
import lottie from "lottie-web";
import { useEffect, useRef, useState } from "react";

const useAnimation = ({
  renderer = "svg",
  loop = true,
  autoplay = true,
  path,
}: Partial<AnimationConfigWithPath>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [animation, setAnimation] = useState<AnimationItem | null>(null);

  useEffect(() => {
    if (ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer,
        loop,
        autoplay,
        path,
      });

      setAnimation(animation);
      return () => {
        animation.stop();
        animation.destroy();
      };
    }
  }, [autoplay, loop, path, renderer]);

  return { ref, animation };
};

export default useAnimation;
